import {Layout} from '../Layout/Layout'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {sbankStatuses} from '../../../../config/sbankStatuses'
import React, {useEffect} from 'react'
import {dispatch} from '../../../../store/store'
import {formSlice} from '../../../../store/form/formSlice'
import {getRequisiteType} from '../../../../config/paymentTypes'
import {updateStatuses} from '../../../../config/updateStatuses'
import {Button, useMediaQuery, useTheme} from '@mui/material'
import {redirect} from '../../../../utils/redirect'
import {stylesObject} from '../stylesObject'
import styles from '../base.module.scss'
import {StatusImage} from '../StatusImage/StatusImage'
import {Amount} from '../Amount/Amount'
import {openExternalURL} from '../../../../utils/openExternalURL';

export function Status() {
  const {t} = useTranslation('translation', {keyPrefix: 'StatusBlock'})
  const {t: ct} = useTranslation('translation', {keyPrefix: 'common'})
  const form = useSelector(store => store.form)
  const {params, update, formData} = form
  const {finish_url} = params
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))

  const payloadStatus = update.data.payload_status

  let paymentStatus = form.status?.data?.payment_status ?? sbankStatuses.PENDING
  if (paymentStatus === sbankStatuses.FAILED && form.status?.data?.message === 'Payment cancelled by customer') {
    paymentStatus = sbankStatuses.CANCELED
  }
  if (payloadStatus === updateStatuses.cancelled) {
    paymentStatus = sbankStatuses.CANCELED
  }
  if (payloadStatus === updateStatuses.timeout) {
    paymentStatus = sbankStatuses.TIMEOUT
  }
  if (payloadStatus === updateStatuses.paid && paymentStatus === sbankStatuses.PENDING) {
    paymentStatus = sbankStatuses.PAID
  }

  // let statusText = t('status.somethingWentWrong')

  useEffect(() => {
    dispatch(formSlice.actions.status())
    const intervalId = setInterval(() => {
      dispatch(formSlice.actions.status())
    }, 2e3)
    return () => {
      clearInterval(intervalId)
    };
  }, []);

  const {beneficiary} = formData.data
  let {displayType} = getRequisiteType(beneficiary, params)

  let amountRaw = params.amount
  if (formData?.data?.is_amount_changed) {
    amountRaw = formData?.data?.amount
  }

  // console.log('paymentStatus', paymentStatus)
  // console.log('payloadStatus', payloadStatus)

  return (
    <Layout
      title={ct(`h1.${displayType}`)}
    >
      {mobile ? (
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {paymentStatus === sbankStatuses.TIMEOUT && (
            <div className={`${styles.textSubtitle} ${styles.center}`} style={{marginBottom: '16px'}}>
              {t('paymentTimeout')}
            </div>
          )}

          {paymentStatus === sbankStatuses.PAID && (
            <div className={`${styles.textSubtitle} ${styles.center}`} style={{marginBottom: '16px'}}>
              {t('paymentPaid')}
            </div>
          )}

          {paymentStatus === sbankStatuses.CANCELED && (
            <div className={`${styles.textSubtitle} ${styles.center}`} style={{marginBottom: '16px'}}>
              {t('paymentCanceled')}
            </div>
          )}

          <div className={`${styles.textSmall} ${styles.center}`} style={{marginBottom: '16px'}}>
            {ct(`order`, {order: '4616ad3e-11b4-4f64-91ab-78b274254d1c'})}
          </div>

          {(paymentStatus !== sbankStatuses.CANCELED || paymentStatus !== sbankStatuses.TIMEOUT) && (
            <div style={{margin: '0 auto 16px auto'}}>
              <Amount
                amount={amountRaw}
              />
            </div>
          )}

          {paymentStatus === sbankStatuses.COMPLETE && (
            <div className={`${styles.textSubtitle} ${styles.center}`}>
              {t('paymentSuccessful')}
            </div>
          )}

          {paymentStatus === sbankStatuses.FAILED && (
            <div className={`${styles.textSubtitle} ${styles.center}`}>
              {t('paymentDeclined')}
            </div>
          )}

          <div style={{marginTop: '16px'}}>
            <StatusImage
              image={paymentStatus}
            />
          </div>

          <div style={{flexGrow: 1}}/>

          <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
            {paymentStatus === sbankStatuses.FAILED && (
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnPrimary,
                }}
                onClick={() => openExternalURL('https://t.me/Jussupport_bot')}
              >
                {t('support')}
              </Button>
            )}
            {finish_url && (
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnPrimary,
                }}
                onClick={() => redirect(finish_url)}
              >
                {t('returnToShop')}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '32px',
            flexGrow: 1,
          }}
        >
          <div>
            <StatusImage
              image={paymentStatus}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            {paymentStatus === sbankStatuses.TIMEOUT && (
              <div className={`${styles.textTitle}`} style={{marginBottom: '12px'}}>
                {t('paymentTimeout')}
              </div>
            )}

            {paymentStatus === sbankStatuses.PAID && (
              <div className={`${styles.textTitle}`} style={{marginBottom: '12px'}}>
                {t('paymentPaid')}
              </div>
            )}

            {paymentStatus === sbankStatuses.CANCELED && (
              <div className={`${styles.textTitle}`} style={{marginBottom: '12px'}}>
                {t('paymentCanceled')}
              </div>
            )}

            <div className={styles.textMedium} style={{marginBottom: '12px'}}>
              {ct(`order`, {order: '4616ad3e-11b4-4f64-91ab-78b274254d1c'})}
            </div>

            {(paymentStatus !== sbankStatuses.CANCELED || paymentStatus !== sbankStatuses.TIMEOUT) && (
              <div style={{marginBottom: '12px'}}>
                <Amount
                  amount={amountRaw}
                />
              </div>
            )}

            {/*{paymentStatus === sbankStatuses.PENDING && (*/}
            {/*  <div style={{*/}
            {/*    flexGrow: 1,*/}
            {/*    margin: `60px 0`,*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'center'*/}
            {/*  }}>*/}
            {/*    <CircularProgress*/}
            {/*      size={120}*/}
            {/*      thickness={2}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            {paymentStatus === sbankStatuses.COMPLETE && (
              <div className={styles.textTitle}>
                {t('paymentSuccessful')}
              </div>
            )}

            {paymentStatus === sbankStatuses.FAILED && (
              <div className={styles.textTitle}>
                {t('paymentDeclined')}
              </div>
            )}

            <div style={{flexGrow: 1}}/>

            <div style={{display: 'flex', marginTop: '24px', gap: '12px'}}>
              {paymentStatus === sbankStatuses.FAILED && (
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{
                    ...stylesObject.btnPrimary,
                  }}
                  onClick={() => openExternalURL('https://t.me/Jussupport_bot')}
                >
                  {t('support')}
                </Button>
              )}
              {finish_url && (
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{
                    ...stylesObject.btnPrimary,
                  }}
                  onClick={() => redirect(finish_url)}
                >
                  {t('returnToShop')}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}
