import {Button, useMediaQuery, useTheme} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {dispatch} from '../../../../store/store'
import {formSlice} from '../../../../store/form/formSlice'
import {useTranslation} from "react-i18next"
import {Layout} from '../Layout/Layout'
import styles from '../base.module.scss'
import {stylesObject} from '../stylesObject'
import {sourceBanksImageMap} from '../../config/sourceBanks'
import {redirect} from '../../../../utils/redirect'
import {useSelector} from 'react-redux'

export function SourceBankList(props) {
  const theme = useTheme()
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'BankList'})
  const mobile = useMediaQuery(theme.breakpoints.down("md"))
  const [marked, setMarked] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const form = useSelector(store => store.form)
  const {params, banks, formData} = form
  const {finish_url} = params

  const sourceBanks = banks?.payment_widget_data?.source_banks ?? {}
  const amount = params.amount

  const selectMarked = () => {
    setIsSubmitted(true)
    dispatch(formSlice.actions.selectSourceBank({bank: marked}))
  }

  const selectBank = useCallback((bank) => {
    return function () {
      setMarked(bank)
      if (mobile) {
        setIsSubmitted(true)
        dispatch(formSlice.actions.selectSourceBank({bank}))
      }
    }
  }, [])

  return (
    <Layout
      title={props.title}
    >
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: !mobile ? null : 'column',
          gap: !mobile ? '32px' : null,
        }}
      >
        {mobile && (
          <div className={`${styles.textSmall} ${styles.center}`}>
            {t('selectBank')}
          </div>
        )}

        <div
          style={!mobile ? {
            display: 'flex',
            flexDirection: 'column',
            gap: '22px',
            width: '384px',
          } : null}
        >
          {Object.keys(sourceBanks)
            .filter(key => {
              const bankData = sourceBanks[key]
              if(bankData.amount_min) {
                return amount < Number(bankData.amount_min)
              }
              return true
            })
            .map(key => {
              const bankData = sourceBanks[key]
              return (
                <Button
                  disabled={isSubmitted}
                  key={key}
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{
                    ...stylesObject.btnBank,
                    background: marked?.key === key ? '#27C74A !important' : null,
                    marginTop: mobile ? '16px' : null,
                  }}
                  startIcon={<div
                    style={{
                      background: `url("${sourceBanksImageMap[bankData.img]}") no-repeat center center`,
                      height: 33,
                      width: 32,
                    }}
                  />}
                  onClick={selectBank(bankData)}
                >
                  {bankData.name_ru}
                </Button>
              )
            })}
        </div>

        {!mobile && (
          <div
            style={{
              width: '412px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className={styles.textTitle}>
              {t('selectBank')}
            </div>
            <div
              style={{marginTop: '12px'}}
              className={`${styles.textSmall}`}
            >
              {t('bankSelectionNotice')}
            </div>

            <div style={{flexGrow: 1}}/>

            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{
                marginTop: '24px',
                ...stylesObject.btnPrimary,
              }}
              disabled={!marked || isSubmitted}
              onClick={selectMarked}
            >
              {t('nextStep')}
            </Button>
          </div>
        )}

        {mobile && (
          <div
            className={`${styles.textSmall} ${styles.center}`}
            style={{marginTop: 16}}
          >
            {t('bankSelectionNotice')}
          </div>
        )}


        {mobile && finish_url && (
          <>
            <div style={{flexGrow: 1}}/>
            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{
                marginTop: '24px',
                ...stylesObject.btnPrimary,
              }}
              onClick={() => redirect(finish_url)}
            >
              {t('returnToShop')}
            </Button>
          </>
        )}
      </div>
    </Layout>
  )
}
