import jpayLogo from '../asssets/jpayLogo.png'
import React from 'react'
import styles from './base.module.scss'

export function JPayLogo(props) {
  return (
    <div
      style={{
        padding: '0 40px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      }}
    >
      <div
        style={{
          flexShrink: 0,
          height: '48px',
          width: '48px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            width: 16,
            height: 16,
            margin: 16,
            borderRadius: '32px',
            boxShadow: `0px 2px 26px 14px #3246FFB2`,
          }}
        />
        <div
          style={{
            position: 'relative',
            zIndex: 2,
            flexShrink: 0,
            height: '48px',
            width: '48px',
            background: `url("${jpayLogo}") no-repeat center center`,
            backgroundSize: 'contain',
          }}
        />
      </div>
      <div className={styles.textTitle} style={{position: 'relative'}}>
        {props.children}
      </div>
    </div>
  )
}
