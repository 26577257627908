import {useMediaQuery, useTheme} from '@mui/material'
import failed from './paymentCancel.webp'
import notFound from './paymentNotFound.webp'
import timeout from './paymentTimeout.webp'
import amountChanged from './paymentAmountChanged.webp'
import pending from './paymentPending.webp'
import complete from './paymentComplete.webp'

const statusMapping = {
  failed: notFound,
  complete: complete,
  pending: pending,
  canceled: failed,

  timeout: timeout,
  // notFound: notFound,
  paid: pending,
  amountChanged: amountChanged,
}

export function StatusImage(props) {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))

  const image = statusMapping[props.image]

  if(mobile) {
    return (
      <div
        style={{
          width: '128px',
          height: '128px',
          margin: '0 auto',
        }}
      >
        <div
          style={{
            width: 'calc(128px + 64px)',
            height: 'calc(128px + 64px)',
            margin: '-32px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: `url("${image}")`,
            backgroundSize: '216px 216px',
          }}
        />
      </div>
    )
  }

  return (
    <div
      style={{
        width: '384px',
        height: '256px',
      }}
    >
      <div
        style={{
          width: 'calc(384px + 64px)',
          height: 'calc(256px + 64px)',
          margin: '-32px',
          background: `url("${image}") no-repeat center center`,
        }}
      />
    </div>
  )
}
