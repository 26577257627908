import {Layout} from '../Layout/Layout';
import {Button, useMediaQuery, useTheme} from '@mui/material';
import styles from '../base.module.scss';
import {Amount} from '../Amount/Amount';
import {StatusImage} from '../StatusImage/StatusImage';
import {stylesObject} from '../stylesObject';
import {redirect} from '../../../../utils/redirect';
import React from 'react';
import {dispatch} from '../../../../store/store';
import {formSlice} from '../../../../store/form/formSlice';
import {getCurrencySymbol} from '../../../../config/currency';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getBankInfo} from '../../../../config/bankInfo';
import {getRequisiteType} from '../../../../config/paymentTypes';

export function AmountChanged(props) {
  const {t} = useTranslation('translation', {keyPrefix: 'AmountChanged'})
  const {t: ct} = useTranslation('translation', {keyPrefix: 'common'})
  const form = useSelector(store => store.form)
  const {params, update, formData} = form
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))
  const {setIsPriceChangedAccepted} = props

  const {beneficiary} = formData.data
  const {displayType} = getRequisiteType(beneficiary, params)

  let amountRaw = params.amount
  if (formData?.data?.is_amount_changed) {
    amountRaw = formData?.data?.amount
  }

  return (
    <Layout
      title={ct(`h1.${displayType}`)}
    >
      {mobile ? (
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className={`${styles.textSmall} ${styles.center}`} style={{marginBottom: '16px'}}>
            {t('amountChangedTitle')}
          </div>

          <div className={`${styles.textSmall} ${styles.center}`}>
            {t('amountChangedSubTitle1')}
          </div>

          <div style={{margin: '16px auto'}}>
            <Amount
              amount={amountRaw}
            />
          </div>

          <div className={`${styles.textSmall} ${styles.center}`} style={{marginBottom: '16px'}}>
            {t('amountChangedSubTitle2')}
          </div>

          <div style={{marginTop: '16px', marginBottom: '56px'}}>
            <StatusImage
              image={'amountChanged'}
            />
          </div>

          <div style={{flexGrow: 1}}/>

          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{
              ...stylesObject.btnPrimary,
              marginBottom: '12px',
            }}
            onClick={() => setIsPriceChangedAccepted(true)}
          >
            {t('amountChangedConfirm')}
          </Button>

          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{
              ...stylesObject.btnSecondary,
            }}
            onClick={() => {
              dispatch(formSlice.actions.update({status: 'cancelled'}))
            }}
          >
            {t('cancel')}
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '32px',
            flexGrow: 1,
          }}
        >
          <div>
            <StatusImage
              image={'amountChanged'}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>

            <div className={`${styles.textMedium}`} style={{marginBottom: '12px'}}>
              {t('amountChangedTitle')}
            </div>

            <div className={`${styles.textMedium}`}>
              {t('amountChangedSubTitle1')}
            </div>

            <div style={{margin: '12px 0'}}>
              <Amount
                amount={amountRaw}
              />
            </div>

            <div className={`${styles.textMedium}`} style={{marginBottom: '12px'}}>
              {t('amountChangedSubTitle2')}
            </div>

            <div style={{flexGrow: 1}}/>

            <div style={{display: 'flex', gap: '12px'}}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnPrimary,
                  marginBottom: '12px',
                }}
                onClick={() => setIsPriceChangedAccepted(true)}
              >
                {t('amountChangedConfirm')}
              </Button>

              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnSecondary,
                }}
                onClick={() => {
                  dispatch(formSlice.actions.update({status: 'cancelled'}))
                }}
              >
                {t('cancel')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}
