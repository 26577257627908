import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import {Environment} from "../config/Environment"
import {en} from "./en"
import {ru} from "./ru"
import {pt} from "./pt"
import {hu} from "./hu"
import {az} from "./az"
import {uz} from "./uz"
import {merge} from 'lodash'
import {appThemes} from '../themes/themes'

const url = new URL(window.location.href)

let language = Environment.REACT_APP_DEFAULT_LANGUAGE ? Environment.REACT_APP_DEFAULT_LANGUAGE : 'ru'

const lp = url.searchParams.get('language')
if (lp && (lp === 'ru' || lp === 'en' || lp === 'pt' || lp === 'hu' || lp === 'az' || lp === 'uz')) {
  language = lp
}

const themeName = process.env.REACT_APP_THEME ?? 'default'
const themeLocalization = appThemes[themeName].localization

i18n
  .use(initReactI18next)
  .init({
    lng: language,
    // fallbackLng: Environment.REACT_APP_DEFAULT_LANGUAGE ? Environment.REACT_APP_DEFAULT_LANGUAGE : 'en',
    // debug: true,
    resources: {
      en: {
        translation: merge({...en}, themeLocalization?.en ?? {}),
      },
      ru: {
        translation: merge({...ru}, themeLocalization?.ru ?? {}),
      },
      pt: {
        translation: {...pt},
      },
      hu: {
        translation: {...hu},
      },
      az: {
        translation: {...az},
      },
      uz: {
        translation: {...uz},
      }
    }
  });


export default i18n;
