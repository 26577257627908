export const stylesObject = {
  btnPrimary: {
    height: '48px',
    padding: '8px 12px',
    boxShadow: 'none !important',
    textTransform: 'none',
    background: "#56C6FF",
    borderRadius: '12px',
    "&:hover": {
      background: '#3c8bb3',
    },
    "&:active": {
      background: "#a2e0ff"
    },
    "&:disabled": {
      color: '#EAF8FF',
      background: '#3c8bb3',
    }
  },
  btnSecondary: {
    height: '48px',
    padding: '8px 12px',
    boxShadow: 'none !important',
    textTransform: 'none',
    background: "#1C3C67",
    borderRadius: '12px',
    "&:hover": {
      background: "#8294ab"
    },
    "&:active": {
      background: '#142a48',
    },
    "&:disabled": {
      color: '#EAF8FF',
      background: "#1C3C67",
    }
  },
  btnBank: {
    height: '48px',
    padding: '8px 12px',
    boxShadow: 'none !important',
    textTransform: 'none',
    justifyContent: 'flex-start',
    background: '#2a4c90',
    borderRadius: '12px',
    "&:hover": {
      background: "#5377c7",
    },
    "&:active": {
      background: "#27C74A !important"
    },
    "&:disabled": {
      color: '#EAF8FF',
      background: "#5377c7",
    }
  },
}
