
export const virtualSbankStatuses = {
  // virtual
  CANCELED: 'canceled',
  TIMEOUT: 'timeout',
  PAID: 'paid',
}

export const sbankStatuses = {
  COMPLETE: 'complete',
  PENDING: 'pending',
  FAILED: 'failed',
  REFUNDED: 'refunded',
  ...virtualSbankStatuses,
}

export function isVirtualSbankStatuses(status) {
  let value = status.toUpperCase()
  return !!virtualSbankStatuses[value]
}
