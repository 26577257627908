import {Layout} from '../Layout/Layout'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import React, {useState} from 'react'
import {getErrorCodeItems} from '../../config/errorCodes'
import {Button, useMediaQuery, useTheme} from '@mui/material'
import styles from '../base.module.scss'
import {StatusImage} from '../StatusImage/StatusImage'
import {stylesObject} from '../stylesObject';
import {openExternalURL} from '../../../../utils/openExternalURL';
import {redirect} from '../../../../utils/redirect';

export function FatalError() {
  const {t} = useTranslation('translation', {keyPrefix: 'FatalError'})
  const form = useSelector(store => store.form)
  const {fatalError, params} = form
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))

  const {finish_url} = params

  const [items] = useState(getErrorCodeItems(fatalError.code))

  let message = t('somethingWentWrong')
  if (fatalError.code && fatalError.code in {
    NO_TERMINALS_AVAILABLE: 1,
    FORM_PAY_ALREADY_SUBMITED: 1,
    COMMON_ERROR: 1,
    DUPLICATE_ORDER_ERROR: 1,
    ROUTE_ERROR: 1,
  }) {
    message = t(`code.${fatalError.code}`)
  }

  return (
    <Layout
      title={'JPAY'}
    >
      {mobile ? (
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className={`${styles.textSubtitle} ${styles.center}`} style={{marginBottom: '16px'}}>
            {message}
          </div>

          <div style={{flexGrow: 1}}/>

          <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{
                ...stylesObject.btnPrimary,
              }}
              onClick={() => openExternalURL('https://t.me/Jussupport_bot')}
            >
              {t('support')}
            </Button>
            {finish_url && (
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnPrimary,
                }}
                onClick={() => redirect(finish_url)}
              >
                {t('returnToShop')}
              </Button>
            )}
          </div>

          {/*{items.map((Component, index) => (*/}
          {/*  <Component*/}
          {/*    key={`key=${index}`}*/}
          {/*    params={params}*/}
          {/*    fatalError={fatalError}*/}
          {/*    t={t}*/}
          {/*  />*/}
          {/*))}*/}

          {/*{paymentStatus === sbankStatuses.CANCELED && (*/}
          {/*  <div className={`${styles.textSubtitle} ${styles.center}`} style={{marginBottom: '16px'}}>*/}
          {/*    {t('paymentCanceled')}*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*<div className={`${styles.textSmall} ${styles.center}`} style={{marginBottom: '16px'}}>*/}
          {/*  {ct(`order`, {order: '4616ad3e-11b4-4f64-91ab-78b274254d1c'})}*/}
          {/*</div>*/}

          {/*{(paymentStatus !== sbankStatuses.CANCELED || paymentStatus !==  sbankStatuses.TIMEOUT) && (*/}
          {/*  <div style={{margin: '0 auto 16px auto'}}>*/}
          {/*    <Amount*/}
          {/*      amount={amountRaw}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{paymentStatus === sbankStatuses.COMPLETE && (*/}
          {/*  <div className={`${styles.textSubtitle} ${styles.center}`}>*/}
          {/*    {t('paymentSuccessful')}*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{paymentStatus === sbankStatuses.FAILED && (*/}
          {/*  <div className={`${styles.textSubtitle} ${styles.center}`}>*/}
          {/*    {t('paymentDeclined')}*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*<div style={{marginTop: '16px'}}>*/}
          {/*  <StatusImage*/}
          {/*    image={paymentStatus}*/}
          {/*  />*/}
          {/*</div>*/}

          {/*<div style={{flexGrow: 1}}/>*/}

          {/*{finish_url && (*/}
          {/*  <Button*/}
          {/*    fullWidth*/}
          {/*    size="large"*/}
          {/*    variant="contained"*/}
          {/*    sx={{*/}
          {/*      ...stylesObject.btnPrimary,*/}
          {/*    }}*/}
          {/*    onClick={() => redirect(finish_url)}*/}
          {/*  >*/}
          {/*    {t('returnToShop')}*/}
          {/*  </Button>*/}
          {/*)}*/}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '32px',
            flexGrow: 1,
          }}
        >
          <div>
            <StatusImage
              image={'failed'}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            <div className={`${styles.textTitle}`} style={{marginBottom: '12px'}}>
              {message}
            </div>


            {/*{paymentStatus === sbankStatuses.CANCELED && (*/}
            {/*  <div className={`${styles.textTitle}`} style={{marginBottom: '12px'}}>*/}
            {/*    {t('paymentCanceled')}*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*<div className={styles.textMedium} style={{marginBottom: '12px'}}>*/}
            {/*  {ct(`order`, {order: '4616ad3e-11b4-4f64-91ab-78b274254d1c'})}*/}
            {/*</div>*/}

            {/*{(paymentStatus !== sbankStatuses.CANCELED || paymentStatus !==  sbankStatuses.TIMEOUT) && (*/}
            {/*  <div style={{marginBottom: '12px'}}>*/}
            {/*    <Amount*/}
            {/*      amount={amountRaw}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*/!*{paymentStatus === sbankStatuses.PENDING && (*!/*/}
            {/*/!*  <div style={{*!/*/}
            {/*/!*    flexGrow: 1,*!/*/}
            {/*/!*    margin: `60px 0`,*!/*/}
            {/*/!*    display: 'flex',*!/*/}
            {/*/!*    alignItems: 'center',*!/*/}
            {/*/!*    justifyContent: 'center'*!/*/}
            {/*/!*  }}>*!/*/}
            {/*/!*    <CircularProgress*!/*/}
            {/*/!*      size={120}*!/*/}
            {/*/!*      thickness={2}*!/*/}
            {/*/!*    />*!/*/}
            {/*/!*  </div>*!/*/}
            {/*/!*)}*!/*/}

            {/*{paymentStatus === sbankStatuses.COMPLETE && (*/}
            {/*  <div className={styles.textTitle}>*/}
            {/*    {t('paymentSuccessful')}*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*{paymentStatus === sbankStatuses.FAILED && (*/}
            {/*  <div className={styles.textTitle}>*/}
            {/*    {t('paymentDeclined')}*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*<div style={{flexGrow: 1}}/>*/}

            {/*{finish_url && (*/}
            {/*  <Button*/}
            {/*    fullWidth*/}
            {/*    size="large"*/}
            {/*    variant="contained"*/}
            {/*    sx={{*/}
            {/*      ...stylesObject.btnPrimary,*/}
            {/*    }}*/}
            {/*    onClick={() => redirect(finish_url)}*/}
            {/*  >*/}
            {/*    {t('returnToShop')}*/}
            {/*  </Button>*/}
            {/*)}*/}

            <div style={{flexGrow: 1}}/>

            <div style={{display: 'flex', marginTop: '24px', gap: '12px'}}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnPrimary,
                }}
                onClick={() => openExternalURL('https://t.me/Jussupport_bot')}
              >
                {t('support')}
              </Button>
              {finish_url && (
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{
                    ...stylesObject.btnPrimary,
                  }}
                  onClick={() => redirect(finish_url)}
                >
                  {t('returnToShop')}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}
