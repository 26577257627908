import {Layout} from '../Layout/Layout'

export function Preloading(props) {
  return (
    <Layout
      empty={true}
    >
      {/*<Loader />*/}
    </Layout>
  )
}
