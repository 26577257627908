import DefaultFormPage from './default/FormPage.js'
import {theme as defaultTheme} from './default/theme.js'
import PincoFormPage from './pinco/FormPage.js'
import {theme as pincoTheme} from './pinco/theme.js'
import {FormPage as pfhFormPage} from './pfh/FormPage.js'
import {theme as pfhTheme} from './pfh/theme.js'
import {FormPage as jpayFormPage} from './jpay/FormPage.js'
import {theme as jpayTheme} from './jpay/theme.js'
import {jpayLocalization} from './jpay/locale/localization'

export const appThemes = {
  default: {
    FormPage: DefaultFormPage,
    theme: defaultTheme,
  },
  default: {
    FormPage: DefaultFormPage,
    theme: defaultTheme,
  },
  pinco: {
    FormPage: PincoFormPage,
    theme: pincoTheme,
  },
  pfh: {
    FormPage: pfhFormPage,
    theme: pfhTheme,
  },
  jpay: {
    FormPage: jpayFormPage,
    theme: jpayTheme,
    localization: jpayLocalization,
  },
}
