import styles from '../base.module.scss'
import {JPayLogo} from '../JPayLogo'
import React from 'react'
import moneyStack from '../../asssets/moneyStack.png'
import carBG from '../../asssets/bg2.webp'
import bg from '../../asssets/bg.webp'
import {useMediaQuery, useTheme} from '@mui/material'

export function Layout(props) {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          position: 'absolute',
          zIndex: 0,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: 'linear-gradient(360deg, #0A113E 0%, #183E85 100%)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          zIndex: 0,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          opacity: .05,
          background: `url("${bg}") center center`,
          mixBlendMode: 'overlay',
          backgroundSize: '150px 150px',
        }}
      />

      <div
        className={styles.carBG}
        style={{
          backgroundImage: `url("${carBG}")`,
        }}
      />
      {!props.empty && (
        <div className={styles.header}>
          <JPayLogo>
            {props.title}
          </JPayLogo>
        </div>
      )}

      <div className={`${styles.card} ${props.empty ? styles.empty : null}`}>
        {props.children}
      </div>

      {props.under && (
        <div className={styles.under}>
          {props.under}
        </div>
      )}

      {!mobile && (
        <div style={{flexGrow: 1}}/>
      )}

      {!props.empty && (
        <div className={styles.footer}>
          <div className={styles.test}/>
          <div
            className={styles.footerWallet}
            style={{
              backgroundImage: `url("${moneyStack}")`,
            }}
          />
        </div>
      )}
    </div>
  )
}
