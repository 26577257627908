import alfa from '../asssets/icons/alfa.svg'
import sber from '../asssets/icons/sber.svg'
import vtb from '../asssets/icons/vtb.svg'
import tinkoff from '../asssets/icons/tinkoff.svg'

export const sourceBanksImageMap = {
  alfa,
  sber,
  vtb,
  tinkoff,
}

export const sourceBanks = {
  sber: {
    name_en: "Sber",
    name_ru: "Сбербанк",
    key: "sber",
    img: 'sber',
    amount_min: "10000.0000",
  },
  tinkoff: {
    name_en: "T-Bank",
    name_ru: "Т-Банк",
    key: "tinkoff",
    img: 'tinkoff',
  },
  vtb: {
    name_en: "VTB",
    name_ru: "ВТБ",
    key: "vtb",
    img: 'vtb',
  },
  alfa: {
    name_en: "Alfa Bank",
    name_ru: "Альфа Банк",
    key: "alfa",
    img: 'alfa',
  }
}
