import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl, InputLabel, MenuItem, Select,
  Tooltip,
  Typography
} from '@mui/material'
import {useSelector} from 'react-redux'
import {getBankInfo} from '../../config/bankInfo'
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import {dispatch} from '../../store/store'
import {formSlice} from '../../store/form/formSlice'
import {copyToClipboard} from '../../utils/copyToClipboard'
import {IconButton} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {getRequisiteType} from '../../config/paymentTypes'
import {Upload} from '../Upload/Upload'
import {apiURL} from '../../config/constants'
import {openReplay} from '../../externals/openreplay'
import {sbpShortLogo} from '../../assets/banks/sbp/sbpImages'
import './cardForm.css'

import sberPayLogo from '../../assets/sberPayLogo.svg'
import {getMobileOperatingSystem} from '../../utils/getMobileOperatingSystem'
import {useTranslation} from "react-i18next"
import {QRCode} from "../QRCode/QRCode"
import {PageHeader} from "../PageHeader/PageHeader"
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {getCurrencySymbol} from "../../config/currency";
import {nowToList} from "../../config/nowToList";

async function upload({invoice_id, file}) {
  const formData = new FormData()
  formData.append("file", file.file)
  formData.append("invoice_id", invoice_id)

  try {
    const response = await fetch(`${apiURL}/p2p-selector/screenshot`, {
      method: 'POST',
      body: formData
    });
    const json = await response.json()
    if (json.status === 'success') {
      return {status: 'success'}
    }
    return {status: 'error'}
  } catch (e) {
    return {status: 'error'}
  }
}

export function CardForm() {
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'CardForm'})
  const {t: ct} = useTranslation('translation', {keyPrefix: 'common'})
  const form = useSelector(store => store.form)
  const {formData, formTime, params, selectedDevice} = form
  const [isPriceChangedModal, setIsPriceChangedModal] = useState(true)

  const [qrCodePlatform, setQRCodePlatform] = useState(null)
  const [qrLink, setQRLink] = useState(null)
  const [instruction, setInstruction] = useState('')

  const languageTag = i18n.language === 'ru' ? 'ru' : 'en'

  useEffect(() => {
    if (!formData.data) {
      dispatch(formSlice.actions.fetchData())
    }
  }, [])

  const [toolTipsState, setToolTipsState] = React.useState({
    account_number: {
      isOpen: false,
      timeout: null,
    },
    pan: {
      isOpen: false,
      timeout: null,
    },
    name: {
      isOpen: false,
      timeout: null,
    },
    phone: {
      isOpen: false,
      timeout: null,
    }
  });
  const handleTooltipClose = (key) => {
    clearTimeout(toolTipsState[key].timeout)
    setToolTipsState({
      ...toolTipsState,
      [key]: {
        isOpen: false,
        timeout: null,
      }
    })
  };
  const handleTooltipOpen = (key) => {
    clearTimeout(toolTipsState[key].timeout)
    setToolTipsState({
      ...toolTipsState,
      [key]: {
        isOpen: true,
        timeout: setTimeout(() => {
          handleTooltipClose(key)
        }, 500)
      }
    })
  };

  const timeLimit = formData?.data?.ttl * 60 - (((Date.now() - formTime) / 1e3) | 0)
  const [time, setTime] = useState(timeLimit)

  const timeMin = String(Math.floor(Math.round(time) / 60)).padStart(2, '0')
  const timeSec = String(Math.round(time) % 60).padStart(2, '0')

  useEffect(() => {
    if (!timeLimit) {
      return
    }
    const started = Date.now()
    setTime(timeLimit - (Date.now() - started) / 1e3)
    const interval = setInterval(() => {
      setTime(timeLimit - (Date.now() - started) / 1e3)
    }, 1e3)
    return () => clearInterval(interval);
  }, [timeLimit]);

  useEffect(() => {
    if (time <= 0) {
      dispatch(formSlice.actions.formTimeout())
    }
  }, [time])

  const [files, setFiles] = useState(form.files.map(file => {
    const newFile = {...file}
    if (newFile.status === 'pending') {
      newFile.status = 'error'
    }
    return newFile
  }))

  const onFile = useCallback(async (file) => {
    const newFile = {
      key: `file-${files.length}`,
      status: 'pending',
      file,
    }
    setFiles((files) => [...files, newFile])
    const {status} = await upload({invoice_id: params.invoice_id, file: newFile})
    newFile.status = status
    setFiles((files) => [...files])
  }, [files, params.invoice_id])

  useEffect(() => {
    dispatch(formSlice.actions.setFiles(files.map(file => ({key: file.key, status: file.status}))))
    dispatch(formSlice.actions.saveInCache())
  }, [files])

  if (formData.loading || !formData.data || (Number.isInteger(time) && time < 0)) {
    return (
      <div style={{flexGrow: 1, marginTop: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress
          size={120}
          thickness={2}
        />
        {/*Загружаем доступные банки*/}
      </div>
    )
  }

  const {beneficiary, proof_required} = formData.data
  const bankInfo = getBankInfo(beneficiary.bank_name)

  let {type: paymentType, displayType, helpTexts, helperTextsMobile, sberPay} = getRequisiteType(beneficiary, params)

  let cardBlockShow = openReplay.getFlag('show-form') ?? true

  const isValid = proof_required ? files.find(file => file.status === 'success') : true

  const sberPaySettings = sberPay && getMobileOperatingSystem() ? sberPay[getMobileOperatingSystem()] : null

  const sberPayQRCodePlatformSelect = (platform) => {
    setQRCodePlatform(platform)
    const sberPaySettings = sberPay[platform]
    const {url, type} = sberPaySettings
    const replace = beneficiary[type]

    const href = url.replace('{replace}', replace)
    if (href) {
      setQRLink(href)
    }
  }

  if (selectedDevice && !qrLink) {
    sberPayQRCodePlatformSelect(selectedDevice)
  }

  const sberPaySubmit = () => {
    const {url, type} = sberPaySettings
    const replace = beneficiary[type]
    if (!replace) {
      return
    }
    const href = url.replace('{replace}', replace)
    if (href) {
      window.location.href = href
    }
  }

  let amountRaw = params.amount
  if (formData?.data?.is_amount_changed) {
    amountRaw = formData?.data?.amount
  }
  const amount = new Intl.NumberFormat('ru-Ru').format(amountRaw).replace(',', '.')

  // form.selectedSourceBank?.key -> all
  // beneficiary.requisite_type -> default
  const nowToFilteredList = nowToList.filter(item => {
    const key = `${form.selectedSourceBank?.key ?? ''}_${beneficiary.requisite_type}`
    return item.value.includes(key)
  })
  const nowToParam = params?.howto && params?.howto.toLowerCase() === 'true'
  const howto = nowToParam && nowToFilteredList.length > 0

  function makeHowToList(instruction) {
    const texts = []
    let index = 0
    while (++index) {
      const key = `CardForm.HowTo.${instruction}.${index.toString()}`
      const text = t(
        `HowTo.${instruction}.${index.toString()}`, {
          country_code: beneficiary.country_code ?? 'country_code',
          country_phone_code: beneficiary.country_phone_code ?? 'country_phone_code',
          country_name: beneficiary.country_name ?? 'country_name',
        })
      if (key === text) {
        break
      }
      texts.push(text)
    }
    return texts
  }

  if(!instruction && nowToFilteredList.length > 0) {
    setInstruction(nowToFilteredList[0].value)
  }

  return (
    <div>
      {formData?.data?.is_amount_changed && isPriceChangedModal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            background: '#fff',
            minHeight: '100vh',
            maxWidth: 432,
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            padding: 32,
            boxSizing: "border-box"
          }}
        >
          <Typography mb={0} variant="h5">
            {t('amountChangedTitle')}
          </Typography>

          {t('amountChangedSubTitle1') && (
            <Typography mb={2} variant="subtitle1">
              {t('amountChangedSubTitle1')}
            </Typography>
          )}

          {t('amountChangedSubTitle2') && (
            <Typography mb={2} variant="subtitle1">
              {t('amountChangedSubTitle2')}
            </Typography>
          )}

          <Typography variant="h4">
            {t('amountChangedText', {amount, currency: getCurrencySymbol(params.currency)})}
          </Typography>

          <Button
            variant="contained"
            fullWidth size="large"
            sx={{mb: 2.5, mt: 4}}
            onClick={() => setIsPriceChangedModal(false)}
          >
            {t('amountChangedConfirm')}
          </Button>

          <Button
            variant="outlined"
            fullWidth size="large"
            onClick={() => {
              dispatch(formSlice.actions.update({status: 'cancelled'}))
            }}
          >
            {t('cancel')}
          </Button>
        </div>
      )}

      <div style={{display: 'flex', alignItems: 'center'}}>
        {displayType === 'sbp' && (
          <div
            style={{
              background: `url("${sbpShortLogo}") no-repeat center center`,
              height: 48,
              width: 48,
              marginRight: 12,
              backgroundSize: 'contain',
            }}
          />
        )}
        <Typography variant="h5">
          {ct(`title.${displayType}`)}
        </Typography>
      </div>

      <PageHeader/>

      {displayType === 'sbp' && (
        <>
          <Typography mt={1} style={{fontSize: 14}}>
            {t('sbpPhone')}
          </Typography>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography style={{fontSize: 20, fontWeight: 600}}>
              {beneficiary.phone}
            </Typography>
            <ClickAwayListener onClickAway={() => handleTooltipClose('phone')}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => handleTooltipClose('phone')}
                open={toolTipsState.phone.isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={ct('copied')}
              >
                <IconButton
                  style={{marginTop: -6}}
                  onClick={() => {
                    copyToClipboard(beneficiary.phone)
                    handleTooltipOpen('phone')
                  }}
                >
                  <ContentCopyIcon color="primary"/>
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          </div>

          <Typography mt={1} style={{fontSize: 14}}>
            {t('sbpBank')}
          </Typography>
          <div style={{height: 34}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div
                style={{
                  background: `url("${bankInfo.shortLogo}") no-repeat center center`,
                  height: 24,
                  width: 24,
                  marginRight: 6,
                  backgroundSize: 'contain',
                }}
              />
              <Typography style={{fontSize: 20, fontWeight: 600}}>
                {bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)}
              </Typography>
            </div>
          </div>
          <Typography mt={1} style={{fontSize: 14}}>
            {t('sbpFio')}
          </Typography>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography style={{fontSize: 20, fontWeight: 600}}>
              {beneficiary.name}
            </Typography>
            <ClickAwayListener onClickAway={() => handleTooltipClose('name')}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => handleTooltipClose('name')}
                open={toolTipsState.name.isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={ct('copied')}
              >
                <IconButton
                  style={{marginTop: -6}}
                  onClick={() => {
                    copyToClipboard(beneficiary.name)
                    handleTooltipOpen('name')
                  }}
                >
                  <ContentCopyIcon color="primary"/>
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          </div>
        </>
      )}

      {displayType === 'sberPay' ? (
        <Box mb={3}>
          {sberPaySettings ? (
            <Button className="sberPayBtn" onClick={sberPaySubmit}>
              <div style={{zIndex: 10, display: 'flex', gap: 12}}>
                <div
                  style={{
                    background: `url("${sberPayLogo}") no-repeat center center`,
                    height: 24,
                    width: 24,
                    backgroundSize: 'contain',
                  }}
                />
                <Typography style={{color: '#fff'}}>
                  SberPay
                </Typography>
              </div>
            </Button>
          ) : !selectedDevice ? (
            <>
              <Typography mb={2} style={{fontSize: 24}}>
                {t('selectYourMobileDevice')}
              </Typography>

              <Box mb={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 16}}>
                <Button
                  className="sberPayBtn"
                  onClick={() => sberPayQRCodePlatformSelect('Android')}
                >
                  <div style={{zIndex: 10, display: 'flex', gap: 8, alignItems: 'center'}}>
                    <div
                      style={{
                        background: `url("${sberPayLogo}") no-repeat center center`,
                        height: 24,
                        width: 24,
                        backgroundSize: 'contain',
                      }}
                    />
                    <Typography variant="h5" style={{color: '#fff', textTransform: 'initial'}}>
                      Pay Android
                    </Typography>
                  </div>
                </Button>
                <Button
                  className="sberPayBtn"
                  onClick={() => sberPayQRCodePlatformSelect('iOS')}
                >
                  <div style={{zIndex: 10, display: 'flex', gap: 8, alignItems: 'center'}}>
                    <div
                      style={{
                        background: `url("${sberPayLogo}") no-repeat center center`,
                        height: 24,
                        width: 24,
                        backgroundSize: 'contain',
                      }}
                    />
                    <Typography variant="h5" style={{color: '#fff', textTransform: 'initial'}}>
                      Pay iPhone
                    </Typography>
                  </div>
                </Button>
              </Box>

              {qrLink && (
                <QRCode data={qrLink} icon={qrCodePlatform}/>
              )}
            </>
          ) : (
            <>
              <Typography mb={2} align='center' style={{fontSize: 20}}>
                Откройте приложение Сбера и отсканируйте QR код
                {/* Оплатите через устройство {selectedDevice} */}
              </Typography>

              {qrLink && (
                <QRCode data={qrLink} icon={qrCodePlatform}/>
              )}
            </>)}
        </Box>
      ) : (
        <>
          {t(`paymentTypes.${displayType}`) && (
            <Typography mb={3} style={{fontSize: 24}} className={proof_required ? "title title1" : null}>
              {t(`paymentTypes.${displayType}`)}
            </Typography>
          )}
        </>
      )}

      {displayType !== 'sberPay' && cardBlockShow && displayType !== 'sbp' && (
        <Card sx={{boxShadow: 6}} style={{borderRadius: 16}}>
          <CardContent>
            {paymentType === 'sbp' ? (
              <Box mb={2} style={{display: 'flex', alignItems: 'center'}}>
                {bankInfo.shortLogo && (
                  <div
                    style={{
                      background: `url("${sbpShortLogo}") no-repeat center center`,
                      height: 40,
                      width: 40,
                      marginRight: 12,
                      backgroundSize: 'contain',
                    }}
                  />
                )}
                <Typography style={{fontSize: 20, fontWeight: 600}}>
                  {bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)}
                </Typography>
              </Box>
            ) : (
              <Box mb={2} style={{display: 'flex', alignItems: 'center'}}>
                {bankInfo.shortLogo && (
                  <div
                    style={{
                      background: `url("${bankInfo.shortLogo}") no-repeat center center`,
                      height: 40,
                      width: 40,
                      marginRight: 12,
                      backgroundSize: 'contain',
                    }}
                  />
                )}
                <Typography style={{fontSize: 20, fontWeight: 600}}>
                  {bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)}
                </Typography>
              </Box>
            )}

            {paymentType === 'card' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('pan')}
                </Typography>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.pan.replaceAll(' ', '').match(/.{1,4}/g).map((str, i) => {
                      return <span key={`key-${i}`} style={{marginRight: 8}}>{str}</span>
                    })}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('pan')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('pan')}
                      open={toolTipsState.pan.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.pan.replaceAll(' ', ''))
                          handleTooltipOpen('pan')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {paymentType === 'phone' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('phone')}
                </Typography>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.phone}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('phone')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('phone')}
                      open={toolTipsState.phone.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.phone)
                          handleTooltipOpen('phone')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {paymentType === 'sbp' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('phone')}
                </Typography>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.phone}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('phone')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('phone')}
                      open={toolTipsState.phone.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.name)
                          handleTooltipOpen('phone')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {paymentType === 'local_banks' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('accountNumber')}
                </Typography>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.account_number}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('account_number')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('account_number')}
                      open={toolTipsState.account_number.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.account_number)
                          handleTooltipOpen('account_number')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {paymentType === 'account' && (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('accountNumber')}
                </Typography>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography
                    style={{fontSize: 18, fontWeight: 600, wordWrap: 'break-word', maxWidth: `calc(100% - 44px)`}}>
                    {beneficiary.account_number}
                  </Typography>

                  <ClickAwayListener onClickAway={() => handleTooltipClose('account_number')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('account_number')}
                      open={toolTipsState.account_number.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.account_number)
                          handleTooltipOpen('account_number')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            )}

            {beneficiary.name ? (
              <>
                <Typography mt={1} style={{fontSize: 14}}>
                  {t('recipient')}
                </Typography>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontSize: 20, fontWeight: 600}}>
                    {beneficiary.name}
                  </Typography>
                  <ClickAwayListener onClickAway={() => handleTooltipClose('name')}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose('name')}
                      open={toolTipsState.name.isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={ct('copied')}
                    >
                      <IconButton
                        style={{marginTop: -6}}
                        onClick={() => {
                          copyToClipboard(beneficiary.name)
                          handleTooltipOpen('name')
                        }}
                      >
                        <ContentCopyIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </>
            ) : (
              <Box mt={4}></Box>
            )}
          </CardContent>
        </Card>
      )}

      <Box mt={2} style={{color: '#666666'}}>
        {t('remainingTime')}
        {' '}
        <Typography component="span" style={{fontSize: 14, color: '#000'}}>
          {timeMin}:{timeSec}
        </Typography>
      </Box>

      {proof_required && (
        <Box>
          <Typography my={3} style={{fontSize: 24}} className={"title title2"}>
            {t('uploadTransactionReceipt')}
          </Typography>

          <Upload
            files={files}
            onChange={onFile}
          />
        </Box>
      )}

      <Box mt={2}>
        <Alert severity="null" style={{padding: 0, display: 'block', width: '100%'}}>

          {howto ? (
            <>
              <AlertTitle mb={2}>
                {t('HowTo.title')}
              </AlertTitle>

              <FormControl fullWidth>
                <InputLabel id="now-to">
                  {t('HowTo.select')}
                </InputLabel>
                <Select
                  labelId="now-to"
                  value={instruction}
                  label={t('HowTo.select')}
                  onChange={(_, {props}) => setInstruction(props.value)}
                >
                  {nowToFilteredList.map(item =>
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              {instruction && (
                <div className={'list'} style={{marginTop: 16}}>
                  {makeHowToList(instruction).map((text, index) => (
                    <span key={`key-${index}`} data-number={`${index + 1}.`}>
                     {text}
                    </span>
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              <AlertTitle>
                {t('attention')}
              </AlertTitle>

              <Typography component="span" color="error" style={{fontSize: 16, color: '#666666'}}>
                <span className={'list'}>
                  {getMobileOperatingSystem() && helperTextsMobile ? (
                    <>
                      {helperTextsMobile.map((tip, index) => {
                        return (
                          <span key={`key-${index}`} data-number={`${index + 1}.`}>
                            {t(`paymentTips.${tip}`)}
                          </span>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {helpTexts.map((tip, index) => {
                        return (
                          <span key={`key-${index}`} data-number={`${index + 1}.`}>
                            {t(`paymentTips.${tip}`, {})}
                          </span>
                        )
                      })}
                    </>
                  )}
                </span>
              </Typography>
            </>
          )}
        </Alert>
      </Box>

      <Button
        onClick={() => dispatch(formSlice.actions.update({status: 'paid'}))}
        sx={{mb: 2.5, mt: 4}}
        fullWidth
        variant="contained"
        size="large"
        disabled={!isValid}
      >
        {t('paymentConfirmed')}
      </Button>

      <Button
        variant="outlined"
        onClick={() => dispatch(formSlice.actions.update({status: 'cancelled'}))}
        fullWidth size="large"
      >
        {t('cancel')}
      </Button>
    </div>
  )
}
